"use client"

import { useEffect } from "react"
import { usePathname, useSearchParams } from "next/navigation"
import { useUser } from "@/hooks/use-user"
import formbricks from "@formbricks/js"

export default function FormbricksProvider() {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const { data } = useUser()

  useEffect(() => {
    if (!data?.userDetail?.email) return
    try {
      let formBrickLocal = localStorage.getItem("formbricks-js")
      let formBrickLocalObject: any = {}
      if (formBrickLocal) {
        formBrickLocalObject = JSON.parse(formBrickLocal)
        if (
          formBrickLocalObject?.personState?.data?.userId !=
          data?.userDetail?.email
        )
          localStorage.removeItem("formbricks-js")
      }
      formbricks.init({
        environmentId: process.env.NEXT_PUBLIC_FORMBRICK_ENVID,
        apiHost: process.env.NEXT_PUBLIC_FORMBRICK_API_HOST,
        userId: data?.userDetail?.email,
      })
    } catch (error) {
      console.error(error)
    }
  }, [data?.userDetail?.email])

  useEffect(() => {
    formbricks?.registerRouteChange()
  }, [pathname, searchParams])
  return null
}
