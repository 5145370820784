"use client"

import { ComponentProps } from "react"
import { ClerkProvider as ClerkProviderBase } from "@clerk/nextjs"
import { dark } from "@clerk/themes"
import { useTheme } from "next-themes"

export const ClerkProvider = ({
  children,
  ...props
}: ComponentProps<typeof ClerkProviderBase>) => {
  const { theme, systemTheme } = useTheme()

  const isDark =
    theme === "dark" || (theme === "system" && systemTheme === "dark")

  return (
    <ClerkProviderBase
      {...props}
      appearance={{
        layout: {
          logoImageUrl: isDark ? "/logo-dark.svg" : "/logo-light.svg",
          // socialButtonsPlacement: "bottom",
          // socialButtonsVariant: "iconButton",
          // termsPageUrl: "/terms",
          // helpPageUrl: "/help",
          // privacyPageUrl: "/privacy",
          // showOptionalFields: true,
          // shimmer: true,
        },
        baseTheme: isDark ? dark : undefined,
      }}
      signInForceRedirectUrl="/sync-account"
      signUpForceRedirectUrl="/sync-account"
      afterSignOutUrl="/sign-out"
    >
      {children}
    </ClerkProviderBase>
  )
}
