"use client"

import BugsnagPerformance from "@bugsnag/browser-performance"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"

let isStarted = false
export const startBugSnag = () => {
  if (!process.env.NEXT_PUBLIC_BUGSNAG_API_KEY) return
  if (isStarted) return
  isStarted = true
  Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.NODE_ENV,
    appVersion: process.env.NEXT_PUBLIC_BUILD_ID,
  })
  BugsnagPerformance.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
    releaseStage: process.env.NODE_ENV,
    appVersion: process.env.NEXT_PUBLIC_BUILD_ID,
  })
}

export const BugSnagDriver = () => {
  startBugSnag()
  return null
}
