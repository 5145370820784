import React from "react"
import Link from "next/link"

import { Logo } from "@/components/logo"

const ComingSoon = () => {
  return (
    <section className="relative py-24">
      <div className="mx-auto w-full max-w-7xl px-4 md:px-5 lg:px-5">
        <div className="inline-flex w-full flex-col items-center justify-end gap-10 rounded-2xl bg-gray-900 p-10 md:gap-16 md:px-16 md:pt-16 lg:gap-28">
          <div className="flex flex-col items-center justify-end gap-10 lg:gap-16">
            <Link href="/" className="flex items-center space-x-2">
              <Logo className="h-[50px] w-[180px] text-[#1A1A1A] dark:text-white" />
            </Link>
            <div className="flex flex-col items-center justify-center gap-10">
              <div className="flex flex-col items-center justify-start gap-2.5">
                <h2 className="font-manrope text-center text-5xl font-bold leading-normal text-purple-400 md:text-6xl">
                  Coming Soon
                </h2>
                <p className="text-center text-base font-normal leading-relaxed text-gray-500">
                  We crafting our the site for you now. <br />
                  Merge your biolinks, sell your digital products, and connect
                  with your audience effortlessly.
                </p>
              </div>
            </div>
          </div>
          <p className="text-center text-sm font-normal leading-snug text-gray-500">
            Get in touch with us:{" "}
            <a
              href="mailto:hi@themergie.com"
              className="transition-all duration-700 ease-in-out hover:text-gray-100"
            >
              hi@themergie.com
            </a>
          </p>
        </div>
      </div>
    </section>
  )
}

export default ComingSoon
