"use client"

import { useEffect, useState } from "react"
import { useUser } from "@/hooks/use-user"
import posthog from "posthog-js"
import { PostHogProvider } from "posthog-js/react"

import ComingSoon from "./(public)/(home)/coming-soon"

if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    person_profiles: "always",
  })
}
export function CSPostHogProvider({ children }) {
  const { isSignedIn, user } = useUser()
  const [isComingSoon, setIsComingSoon] = useState<boolean>(null)
  useEffect(() => {
    if (isSignedIn && user) {
      posthog.identify(
        user.email,
        { email: user.email, name: user.username } // optional: set additional person properties
      )
    } else {
      posthog.reset(true)
    }
  }, [user, isSignedIn])

  useEffect(() => {
    document.body.addEventListener("click", function (event: any) {
      if (event.target.hasAttribute("data-ph-event")) {
        const eventName = event.target.getAttribute("data-ph-event")
        const eventValue = event.target.getAttribute("data-ph-value")
        posthog.capture(eventName, {
          value: eventValue,
        })
      }
    })
    posthog.onFeatureFlags(function () {
      if (posthog.isFeatureEnabled("isComingSoon")) {
        setIsComingSoon(true)
      } else {
        setIsComingSoon(false)
      }
    })
  }, [])
  return (
    <PostHogProvider client={posthog}>
      {process.env.NEXT_PUBLIC_POSTHOG_KEY
        ? isComingSoon !== null &&
          (isComingSoon === true ? <ComingSoon /> : children)
        : children}
    </PostHogProvider>
  )
}
